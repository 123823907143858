<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <span class="subtitle-1 font-weight-bold text-uppercase"
          >Novo Usuário</span
        >
        <v-icon color="black" @click="$emit('close');email = null">mdi-close</v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-text-field
          v-model="email"
          placeholder="Digite o email"
          outlined
          dense
          :hide-details="true"
          :clearable="true"
          label="E-mail do usuário:"
          class="pa-4 my-4"
        >
        </v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="fmq_gray" outlined @click="$emit('close');email = null">
          Cancelar
        </v-btn>
        <v-btn color="fmq_gray" @click="$emit('send', { email: email });email = null" dark :loading="loading">
          Enviar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FuncionarioImport",
  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    email: null,
  }),
};
</script>

<style></style>
