<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">Lista de funcionários</p>
        <v-btn
          v-if="checkPermission('employee.import')"
          small
          depressed
          color="fmq_gray"
          dark
          @click="dialog = !dialog"
          >Incluir novo Usuário</v-btn
        >
        <FuncionarioImport
          :dialog="dialog"
          :loading="loadingBtn"
          @close="dialog = !dialog"
          @send="postImportEmployee"
        />
      </div>
      <FuncionariosTabela
        :headers="headers"
        :funcionarios="funcionarios"
        :total="totalFuncionarios"
        :page="formData.page"
        :pageText="pageText"
        :loading="loading"
        @handleFilter="filter"
      >
        <template v-slot:name>
          <v-text-field
            v-model="formData.name"
            placeholder="Busque pelo nome"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              getSearchEmployee(formData);
              formData.page = 1;
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:email>
          <v-text-field
            v-model="formData.email"
            placeholder="Busque pelo email"
            solo
            flat
            dense
            :hide-details="true"
            @input="
              getSearchEmployee(formData);
              formData.page = 1;
            "
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`user.type`]>
          <v-autocomplete
            v-model="formData.roleId"
            :items="listRoles"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pela permissão"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              getSearchEmployee(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`saleForce.code`]>
          <v-autocomplete
            v-model="formData.salesForcesId"
            :items="ListSales"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pela força de venda"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              getSearchEmployee(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`sector.code`]>
          <v-autocomplete
            v-model="formData.sectorsId"
            :items="ListSectors"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pelo setor"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              getSearchEmployee(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`district.code`]>
          <v-autocomplete
            v-model="formData.districtId"
            :items="ListDistricts"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pelo distrito"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              getSearchEmployee(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:[`regional.code`]>
          <v-autocomplete
            v-model="formData.regionalsId"
            :items="ListRegionals"
            hide-no-data
            hide-selected
            item-text="label"
            item-value="id"
            placeholder="Busque pelo regional"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              getSearchEmployee(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:status>
          <v-autocomplete
            v-model="formData.status"
            :items="listStatus"
            hide-no-data
            hide-selected
            item-text="status"
            placeholder="Busque pelo status"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="
              getSearchEmployee(formData);
              formData.page = 1;
            "
          ></v-autocomplete>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </FuncionariosTabela>
      <AlertSuccess
        :dialog="success"
        dialogMessage="Funcionário adicionado com sucesso"
        @close="success = !success"
      />
      <AlertError :alertError="error" :messageError="messageError" />
    </div>
  </div>
</template>

<script>
import { checkPermission } from "@/utils";
import FuncionarioImport from "@/components/funcionarios/FuncionarioImport.vue";
import FuncionariosTabela from "@/components/funcionarios/FuncionariosTabela.vue";
import { searchEmployee, importEmployee } from "@/services/employee";
import { listRolePermission } from "@/services/permission";
import { listSaleForce } from "@/services/sale-force";
import { listSector } from "@/services/sector.js";
import { listDistrict } from "@/services/district.js";
import { listRegional } from "@/services/regional.js";
export default {
  name: "Funcionarios",
  components: { FuncionariosTabela, FuncionarioImport },
  data: () => ({
    breadcrumbs: [
      {
        text: "Funcionários",
        disabled: true,
        to: "funcionarios",
      },
    ],
    headers: [
      { text: "Nome", value: "name" },
      { text: "Email", value: "email" },
      { text: "Tipo", value: "user.type" },
      { text: "Força de Venda", value: "saleForce.code" },
      { text: "Setor", value: "sector.code" },
      { text: "Distrital", value: "district.code" },
      { text: "Regional", value: "regional.code" },
      { text: "Status", value: "status" },
      { text: "Ações", value: "id", sortable: false },
    ],
    formData: {
      sort: null,
      perPage: 20,
      page: 1,
      name: null,
      email: null,
      roleId: null,
      salesForcesId: null,
      sectorsId: null,
      districtId: null,
      regionalsId: null,
      status: null,
    },
    funcionarios: [],
    totalFuncionarios: null,
    pageText: null,
    loading: false,
    listRoles: [],
    ListSales: [],
    listStatus: ["Ativo", "Inativo"],
    ListSectors: [],
    ListDistricts: [],
    ListRegionals: [],
    dialog: false,
    error: false,
    success: false,
    messageError: null,
    loadingBtn: false,
  }),
  created() {
    if (this.$store.state.searchEmployeeHistory.length) {
      this.formData = { ...this.$store.state.searchEmployeeHistory[0] };
    }
    this.getSearchEmployee(this.formData);
    this.getListRolePermission();
    this.getListSaleForce();
    this.getListSector();
    this.getListDistrict();
    this.getListRegional();
  },
  methods: {
    checkPermission,
    async getSearchEmployee(payload) {
      this.funcionarios = [];
      this.$store.dispatch("setSearchEmployeeHistory", payload);
      try {
        this.loading = true;
        const resp = await searchEmployee(payload);
        this.funcionarios = resp.data;
        this.totalFuncionarios = resp.meta.total;
        this.formData.page = resp.meta.currentPage;
        this.pageText = `${resp.meta.from}-${resp.meta.to} de ${resp.meta.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    async getListRolePermission() {
      const resp = await listRolePermission();
      this.listRoles = resp.data;
    },
    async getListSaleForce() {
      const resp = await listSaleForce();
      this.ListSales = resp.data;
    },
    async getListSector() {
      const resp = await listSector();
      this.ListSectors = resp.data;
    },
    async getListDistrict() {
      const resp = await listDistrict();
      this.ListDistricts = resp.data;
    },
    async getListRegional() {
      const resp = await listRegional();
      this.ListRegionals = resp.data;
    },
    async postImportEmployee(payload) {
      this.error = false;
      this.sucess = false;
      this.loadingBtn = true;
      try {
        await importEmployee(payload).then(() => {
          this.success = true;
        });
        this.loadingBtn = false;
        this.dialog = false;
      } catch (e) {
        this.dialog = false;
        this.loadingBtn = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    filter(event) {
      this.$store.dispatch("setSearchEmployeeHistory", this.formData);
      if (event.orderBy) {
        this.formData.sort = event.orderBy + event.orderSorted;
      } else {
        this.formData.sort = null;
      }
      this.formData.perPage = event.perPage;
      this.formData.page = event.page;
      this.getSearchEmployee(this.formData);
    },
    clear() {
      this.$store.dispatch("setSearchEmployeeHistory", {});
      this.formData.name = null;
      this.formData.email = null;
      this.formData.roleId = null;
      this.formData.salesForcesId = null;
      this.formData.sectorsId = null;
      this.formData.districtId = null;
      this.formData.regionalsId = null;
      this.formData.status = null;
      this.formData.page = 1;
      this.getSearchEmployee(this.formData);
    },
    search() {
      this.formData.page = 1;
      this.getSearchEmployee(this.formData);
    },
  },
};
</script>
