var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"elevation-1 tabela-funcionarios",attrs:{"headers":_vm.headers,"items":_vm.funcionarios,"items-per-page":20,"page":_vm.page,"locale":"pt-BR","loading-text":"Carregando","no-data-text":"Nenhum dado encontrado","loading":_vm.loading,"server-items-length":_vm.total,"footer-props":{
    'items-per-page-text': 'Funcionários por página:',
    'items-per-page-options': [20, 50, 100],
    'show-first-last-page': true,
    'show-current-page': true,
    'page-text': _vm.pageText,
  }},on:{"update:options":_vm.handleFilter},scopedSlots:_vm._u([{key:`body.prepend`,fn:function(){return [_c('tr',[_vm._l((_vm.headers),function(header,index){return [_c('th',{key:index},[_vm._t(header.value)],2)]})],2)]},proxy:true},{key:`item.id`,fn:function({ item }){return [(_vm.checkPermission('employee.edit'))?_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":""},on:{"click":function($event){return _vm.gotToFuncionario(item.id)}}},[_vm._v("Detalhes")]):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }