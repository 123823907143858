var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-breadcrumbs',{staticClass:"grey lighten-2 white--text",attrs:{"items":_vm.breadcrumbs}}),_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-4"},[_c('p',{staticClass:"subtitle-1 ma-0"},[_vm._v("Lista de funcionários")]),(_vm.checkPermission('employee.import'))?_c('v-btn',{attrs:{"small":"","depressed":"","color":"fmq_gray","dark":""},on:{"click":function($event){_vm.dialog = !_vm.dialog}}},[_vm._v("Incluir novo Usuário")]):_vm._e(),_c('FuncionarioImport',{attrs:{"dialog":_vm.dialog,"loading":_vm.loadingBtn},on:{"close":function($event){_vm.dialog = !_vm.dialog},"send":_vm.postImportEmployee}})],1),_c('FuncionariosTabela',{attrs:{"headers":_vm.headers,"funcionarios":_vm.funcionarios,"total":_vm.totalFuncionarios,"page":_vm.formData.page,"pageText":_vm.pageText,"loading":_vm.loading},on:{"handleFilter":_vm.filter},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pelo nome","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.getSearchEmployee(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]},proxy:true},{key:"email",fn:function(){return [_c('v-text-field',{attrs:{"placeholder":"Busque pelo email","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.getSearchEmployee(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}})]},proxy:true},{key:`user.type`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listRoles,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pela permissão","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.getSearchEmployee(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.roleId),callback:function ($$v) {_vm.$set(_vm.formData, "roleId", $$v)},expression:"formData.roleId"}})]},proxy:true},{key:`saleForce.code`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.ListSales,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pela força de venda","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.getSearchEmployee(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.salesForcesId),callback:function ($$v) {_vm.$set(_vm.formData, "salesForcesId", $$v)},expression:"formData.salesForcesId"}})]},proxy:true},{key:`sector.code`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.ListSectors,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo setor","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.getSearchEmployee(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.sectorsId),callback:function ($$v) {_vm.$set(_vm.formData, "sectorsId", $$v)},expression:"formData.sectorsId"}})]},proxy:true},{key:`district.code`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.ListDistricts,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo distrito","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.getSearchEmployee(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.districtId),callback:function ($$v) {_vm.$set(_vm.formData, "districtId", $$v)},expression:"formData.districtId"}})]},proxy:true},{key:`regional.code`,fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.ListRegionals,"hide-no-data":"","hide-selected":"","item-text":"label","item-value":"id","placeholder":"Busque pelo regional","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.getSearchEmployee(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.regionalsId),callback:function ($$v) {_vm.$set(_vm.formData, "regionalsId", $$v)},expression:"formData.regionalsId"}})]},proxy:true},{key:"status",fn:function(){return [_c('v-autocomplete',{attrs:{"items":_vm.listStatus,"hide-no-data":"","hide-selected":"","item-text":"status","placeholder":"Busque pelo status","solo":"","flat":"","dense":"","hide-details":true,"clearable":true},on:{"input":function($event){_vm.getSearchEmployee(_vm.formData);
            _vm.formData.page = 1;}},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}})]},proxy:true},{key:"id",fn:function(){return [_c('div',{staticClass:"d-flex justify-start"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","x-small":"","fab":"","color":"red"},on:{"click":_vm.clear}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"outlined":"","x-small":"","fab":"","color":"blue"},on:{"click":_vm.search}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1)]},proxy:true}],null,true)}),_c('AlertSuccess',{attrs:{"dialog":_vm.success,"dialogMessage":"Funcionário adicionado com sucesso"},on:{"close":function($event){_vm.success = !_vm.success}}}),_c('AlertError',{attrs:{"alertError":_vm.error,"messageError":_vm.messageError}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }